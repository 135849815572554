import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_BASE_URL } from '../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { timer } from 'rxjs';

export interface DialogData {
  any
}

// 清掃員情報
export interface Staff {
  staff: string;
  latitude: string;
  longitude: string;
  address1: string;
  address2: string;
  tel: string;
  siteData: []
}
// 物件情報
export interface Site {
  cdSite: string;
  isStaff: Number;
  workType: string;
  staffData: [],
  siteData: [],
  site1: string,
  site2: string,
  address: string,
  client1: string,
  client2: string,
  longitude: string;
  latitude: string;
}

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.less']
})

export class MapDialogComponent implements OnInit, AfterViewInit {

  // ローディング
  nowLoading = true;

  // スタッフチェックボックス
  checkStaff = true;
  // 欠員物件チェックボックス
  checkVacancySite = true;
  // 物件チェックボックス
  checkSite = true;

  // ピンの保持データ
  staffPin: Staff[];
  vacancySitePin: Site[];
  sitePin: Site[];

  // 画面左に表示する内容
  staff = {};
  vacancy = {};
  site = {};

  // スタッフのマーカー
  staffMarkers = []
  // 欠員物件のマーカー
  vacancySiteMarkers = []
  // 物件のマーカー
  siteMarkers = []

  // クリックしたピンの種類
  type = '';

  map = null

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<MapDialogComponent>, private changeDetectionRef: ChangeDetectorRef, private ngZone: NgZone) { }

  async ngOnInit() {
    this.nowLoading = true;

    // データ取得
    await this.getData();

    // マップ生成
    await this.createMap();

    this.nowLoading = false;
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  async ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
    // await this.createMap();
  }

  /**
   * マップ生成
   */
  async createMap() {
    const { maps } = (window as any).google;
    const { AdvancedMarkerElement, PinElement } = await maps.importLibrary("marker");

    // マップ生成
    this.map = new maps.Map(document.getElementById("map") as HTMLElement, {
      zoom: 10,
      center: { lat: 35.6894, lng: 139.6917 },    // 東京
      mapId: 'akbs4504f8b37365c3d0',
    });

    // スタッフマーカー生成
    this.staffPin.forEach(staff => {
      if (staff.latitude) {
        const pin = new PinElement({
          scale: 0.7,
          background: 'orange',
          borderColor: 'chocolate',
          glyphColor: 'chocolate'
        });
    
        // マーカー生成
        const marker = new AdvancedMarkerElement({
          map: this.map,
          title: staff.staff,
          position: { lat: Number(staff.latitude), lng: Number(staff.longitude) },
          content: pin.element,
          gmpClickable: true,
        });
        marker.staff = staff.staff
        marker.address1 = staff.address1;
        marker.address2 = staff.address2;
        marker.tel = staff.tel;
        marker.siteData = staff.siteData;

        // マーカー クリックイベント
        marker.addListener('click', async () => {
          this.staff = marker
          this.type = 'staff';
          // 物件情報整理
          if (this.staff['siteData']) {
            let tar = this.staff['siteData'].replace('{', '').replace('}', '')
            let tar2 = tar.split(',')
            let row = []
            for (let i = 0; i <tar2.length; i++) {
              const s = tar2[i]
              if (s.indexOf('@@') > -1) {
                const val = s.split('@@')
                if (val && val.length) {
                  row.push({ cdSite: val[0], site1:  val[1] || '', site2:  val[2] || '', address:  val[3] || '', client1:  val[4] || '', client2:  val[5] || '',  })
                }
              }
            }
            this.staff['siteInfo'] = row
          }
        })

        this.staffMarkers.push(marker);
      }
    })

    // 物件マーカー生成
    this.sitePin.forEach(site => {
      if (site.latitude) {
        const pin = new PinElement({
          scale: 0.7,
          background: 'khaki',
          borderColor: 'tan',
          glyphColor: 'tan'
        });
    
        // マーカー生成
        const marker = new AdvancedMarkerElement({
          map: this.map,
          title: site.site1 + site.site2,
          position: { lat: Number(site.latitude), lng: Number(site.longitude) },
          content: pin.element,
          gmpClickable: true,
        });
        this.setSiteMarker(marker, site)

        // マーカー クリックイベント
        marker.addListener('click', async () => {
          this.site = marker
          this.type = 'site';
          this.setInfo(this.site['isStaff']);
        })

        this.siteMarkers.push(marker);
      }
    })

    // 欠員物件マーカー生成（赤）
    this.vacancySitePin.forEach(site => {
      if (site.latitude) {
        const pin = new PinElement({
          scale: 0.7
        });
    
        // マーカー生成
        const marker = new AdvancedMarkerElement({
          map: this.map,
          title: site.site1 + site.site2,
          position: { lat: Number(site.latitude), lng: Number(site.longitude) },
          content: pin.element,
          gmpClickable: true,
        });
        this.setSiteMarker(marker, site)

        // マーカー クリックイベント
        marker.addListener('click', async () => {
          this.site = marker
          this.type = 'site';
          this.setInfo(this.site['isStaff'])
        })

        this.vacancySiteMarkers.push(marker);
      }
    })
  }

  /**
   * 物件のマーカーに必要なデータをセット
   * @param marker マーカー
   * @param site 物件データ
   */
  setSiteMarker(marker, site) {
    marker.site1 = site.site1;
    marker.site2 = site.site2;
    marker.cdSite = site.cdSite;
    marker.address = site.address;
    // 清掃員or管理員
    marker.workType = site.workType;
    // 得意先名
    marker.client1 = site.client1;
    // 支店名
    marker.client2 = site.client2;
    // 欠員物件：-1
    marker.isStaff = site.isStaff;
    // 清掃員情報
    marker.staffData = site.staffData;
    // 契約情報
    marker.siteData = site.siteData;
  }

  /**
   * 清掃員、物件情報を整理
   * @param isStaff 欠員物件：-1
   */
  setInfo(isStaff) {
    let res = this.site
    // 物件情報整理
    if (res['siteData']) {
      let tar = res['siteData'].replace('{', '').replace('}', '')
      let tar2 = tar.split('@*@,')
      // 清掃員いる契約
      let site = []
      // 清掃員いない契約
      let vac = []

      for (let i = 0; i <tar2.length; i++) {
        const s = tar2[i]
        if (s.indexOf('@@') > -1) {
          const val = s.replace('@*@', '').split('@@')
          // console.log(val)
          if (val && val.length) {
            let row = {}
            row = { isStaff: val[0].replace(/"/g, ''), cdContract: val[1] || '', workType:  val[2] || '', mon:  val[3] || '', tue:  val[4] || '', wed:  val[5] || '', thu:  val[6] || '', fri:  val[7] || '', sat:  val[8] || '', sun:  val[9].replace(/"/g, '') || '' }
            if (val[0] == '-1') {
              vac.push(row)
            } else {
              site.push(row)
            }
          }
        }
      }
      res['siteInfo'] = vac.concat(site)
    }

    // 清掃員情報整理
    if (res['staffData']) {
      let tar = res['staffData'].replace('{', '').replace('}', '')
      let tar2 = tar.split(',')
      let sta = []

      for (let i = 0; i <tar2.length; i++) {
        const s = tar2[i]
        if (s.indexOf('@@') > -1) {
          const val = s.split('@@')
          if (val && val.length && val[0]) {
            let row = {}
            row = { cdStaff: val[0], name: val[1] || '', tel:  val[2] || '' }
            sta.push(row)
          }
        }
      }
      res['staffInfo'] = sta
    }
  }

  /**
   * 清掃員、物件情報取得
   */
  async getData() {
    await this.http.get(
      API_BASE_URL + "/api/v1/map"
        , {headers: this.headers})
      .toPromise().then((res: any) => {
        // 清掃員
        this.staffPin = res.staffs;
        // 物件情報を欠員物件と欠員でない物件に分ける
        this.vacancySitePin = []
        this.sitePin = []
        const s = res.sites;
        for (let i = 0; i < s.length; i++) {
          const sd = s[i];
          if (sd.isStaff == -1) {
            this.vacancySitePin.push(sd)
          } else {
            this.sitePin.push(sd)
          }
        }
      })
      .catch((error: any) => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
    );
  }

  /**
   * 閉じる ボタン イベント
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * 従業員 チェックボックス change イベント
   * @param event 
   */
  changeStaff(event) {
    this.nowLoading = true;

    const loadtimer$ = timer(200);
    loadtimer$.subscribe((value) => {
      // マーカー表示切替
      for (const marker of this.staffMarkers) {
        if (this.checkStaff) {
          marker.setMap(this.map);
        } else {
          marker.setMap(null);
        }
      }

      // GoogleMap反映待ち
      const maptimer$ = timer(500);
      maptimer$.subscribe((value) => {
        this.nowLoading = false;
      });
    });
  }

  /**
   * 欠員物件 チェックボックス change イベント
   * @param event 
   */
  changeVacancySite(event) {
    this.nowLoading = true;

    const loadtimer$ = timer(200);
    loadtimer$.subscribe((value) => {
      // マーカー表示切替
      for (const marker of this.vacancySiteMarkers) {
        if (this.checkVacancySite) {
          marker.setMap(this.map);
        } else {
          marker.setMap(null);
        }
      }

      // GoogleMap反映待ち
      const maptimer$ = timer(500);
      maptimer$.subscribe((value) => {
        this.nowLoading = false;
      });
    });
  }

  /**
   * 物件 チェックボックス change イベント
   * @param event 
   */
  changeSite(event) {
    this.nowLoading = true;

    const loadtimer$ = timer(200);
    loadtimer$.subscribe((value) => {
      // マーカー表示切替
      for (const marker of this.siteMarkers) {
        if (this.checkSite) {
          marker.setMap(this.map);
        } else {
          marker.setMap(null);
        }
      }

      // GoogleMap反映待ち
      const maptimer$ = timer(500);
      maptimer$.subscribe((value) => {
        this.nowLoading = false;
      });
    });
  }

  isVacancy(isStaff) {
    if (isStaff == '-1') {
      return 'vacancy-item'
    } else {
      return 'site-item'
    }
  }
}
